import { useLocation, NavLink as RouterNavLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import "./resources/css/NavBar.css";
import LogoImage from "./resources/images/MD_logo.svg";

const Nav = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  const renderNavLink = (to: string, text: string) => (
    <RouterNavLink
      to={to}
      end
      className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
    >
      {text}
    </RouterNavLink>
  );

  const renderScrollLink = (to: string, text: string) => (
    <ScrollLink
      className="nav-link"
      to={to}
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      activeClass="active"
    >
      {text}
    </ScrollLink>
  );

  return (
    <nav className="navbar navbar-expand-lg navbar-scroll fixed-top shadow-0 border-dark">
      <div className="container navbar-side">
        {isHomePage ? (
          <ScrollLink
            to="home"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="navbar-brand"
          >
            <img style={{ height: 50, width: 50 }} src={LogoImage} alt="hero" />
          </ScrollLink>
        ) : (
          <RouterNavLink to="/" className="navbar-brand">
            <img style={{ height: 50, width: 50 }} src={LogoImage} alt="hero" />
          </RouterNavLink>
        )}

        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              {isHomePage
                ? renderScrollLink("home", "HomePage")
                : renderNavLink("/", "HomePage")}
            </li>
            <li className="nav-item">
              {isHomePage
                ? renderScrollLink("work-experience", "WorkExperience")
                : renderNavLink("/", "WorkExperience")}
            </li>
            <li className="nav-item">
              {isHomePage
                ? renderScrollLink("contact-form", "Contact")
                : renderNavLink("/", "Contact")}
            </li>

            <li className="nav-item">{renderNavLink("/Resume", "Resume")}</li>
            <li className="nav-item">{renderNavLink("/code", "LeetCode")}</li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
